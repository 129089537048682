import { api } from '@culture/common-helpers/request';

import { systemMessageActions } from 'ducks/systemMessage';

import * as types from './types';

export function submitMisprintFormWithoutMessage(data) {
	return (dispatch) => {
		dispatch({
			type: types.MISPRINT_FORM_SUBMIT_START
		});

		const misprintPromise = api('misprint', data, 'POST');

		misprintPromise
			.then((response) => {
				dispatch({
					type: types.MISPRINT_FORM_SUBMIT_SUCCESS,
					payload: response
				});
			})
			.catch((err) => {
				dispatch({
					type: types.MISPRINT_FORM_SUBMIT_ERROR,
					payload: err
				});
			});

		return misprintPromise;
	};
}

export function submitMisprintForm(data) {
	return (dispatch) => {
		dispatch(submitMisprintFormWithoutMessage(data))
			.then(() => {
				dispatch(
					systemMessageActions.createMessage({
						text: 'Спасибо, ваше сообщение отправлено'
					})
				);
			})
			.catch(() => {
				dispatch(
					systemMessageActions.createMessage({
						text: 'При отправке произошла ошибка. Попробуйте еще\u00A0раз.'
					})
				);
			});
	};
}
