import * as types from './types';

const initialState = {
	src: '',
	playerId: ''
};

const audioPlayerReducers = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.SAVE_CURRENT_AUDIO:
			return { ...state, ...payload };
		default:
			return state;
	}
};

export default audioPlayerReducers;
