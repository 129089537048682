import React from 'react';
import getConfig from 'next/config';

const {
	publicRuntimeConfig: { assetPrefix }
} = getConfig();

const Fonts = () => (
	<style jsx global>{`
		// fontello
		@font-face {
			font-weight: 400;
			font-family: 'fontello';
			font-style: normal;
			src: url('${assetPrefix}fonts/fontello/fontello.woff2') format('woff2');
		}
		
		// PT Sans
		@font-face {
			font-weight: 400;
			font-family: 'PT Sans';
			font-style: normal;
			src:
				local('PT Sans'),
				local('PTSans-Regular'),
				url('${assetPrefix}fonts/PTSans/PTSansRegular.woff2') format('woff2');
			font-display: swap;
		}

		@font-face {
			font-weight: 400;
			font-family: 'PT Sans';
			font-style: italic;
		  	src:
				local('PT Sans Italic'),
				local('PTSans-Italic'),
				url('${assetPrefix}fonts/PTSans/PTSansItalic.woff2') format('woff2');
			font-display: swap;
		}

		@font-face {
			font-weight: 700;
			font-family: 'PT Sans';
			font-style: normal;
			src:
				local('PT Sans Bold'),
				local('PTSans-Bold'),
				url('${assetPrefix}fonts/PTSans/PTSansBold.woff2') format('woff2');
			font-display: swap;
		}
		
		// Noto Serif
		@font-face {
			font-weight: 400;
			font-family: 'Noto Serif';
			font-style: normal;
			src:
				local('Noto Serif'),
				local('NotoSerif-Regular'),
				url('${assetPrefix}fonts/NotoSerif/NotoSerif-Regular.woff2') format('woff2');
			font-display: swap;
		}
		
		@font-face {
			font-weight: 400;
			font-family: 'Noto Serif';
			font-style: italic;
			src:
				local('Noto Serif Italic'),
				local('NotoSerif-Italic'),
				url('${assetPrefix}fonts/NotoSerif/NotoSerif-RegularItalic.woff2') format('woff2');
			font-display: swap;
		}
		
		@font-face {
			font-weight: 700;
			font-family: 'Noto Serif';
			font-style: normal;
			src:
				local('Noto Serif Bold'),
				local('NotoSerif-Bold'),
				url('${assetPrefix}fonts/NotoSerif/NotoSerif-Bold.woff2') format('woff2');
			font-display: swap;
		}
		
		@font-face {
			font-weight: 700;
			font-family: 'Noto Serif';
			font-style: italic;
			src:
				local('Noto Serif Bold Italic'),
				local('NotoSerif-Bold Italic'),
				url('${assetPrefix}fonts/NotoSerif/NotoSerif-BoldItalic.woff2') format('woff2');
			font-display: swap;
		}
		
		// Dela Gothic One (урезанный шрифт)
		@font-face {
			font-weight: 400;
			font-family: 'Dela Gothic One';
			font-style: normal;
			src: local('Dela Gothic One Regular'), local('DelaGothicOne-Regular'),
				url('${assetPrefix}fonts/DelaGothicOne/DelaGothicOneCyrillic-Regular.woff2')
					format('woff');
			font-display: swap;
			unicode-range: U+41A,U+41F,U+430,U+438,U+43A,U+43D,U+440-443,U+448,U+44F;
		}
		
		// Roboto
		@font-face {
			font-weight: 400;
			font-family: 'Roboto';
			font-style: normal;
			src:
				local('Roboto Regular'),
				local('Roboto-Regular'),
				url('${assetPrefix}fonts/Roboto/Roboto-Regular.woff2') format('woff2');
			font-display: swap;
		}
		
		@font-face {
			font-weight: 700;
			font-family: 'Roboto';
			font-style: normal;
			src:
				local('Roboto Bold'),
				local('Roboto-Bold'),
				url('${assetPrefix}fonts/Roboto/Roboto-Bold.woff2') format('woff2');
			font-display: swap;
		}
	`}</style>
);

export default Fonts;
