import { api } from '@culture/common-helpers/request';

import { systemMessageActions } from 'ducks/systemMessage';
import * as types from './types';

export function submitFeedbackFormWithoutMessage(data) {
	return (dispatch) => {
		dispatch({
			type: types.FEEDBACK_FORM_SUBMIT_START
		});

		const feedbackPromise = api('feedback', data, 'POST', { multipart: true });

		feedbackPromise
			.then((response) => {
				dispatch({
					type: types.FEEDBACK_FORM_SUBMIT_SUCCESS,
					payload: response
				});
			})
			.catch((err) => {
				dispatch({
					type: types.FEEDBACK_FORM_SUBMIT_ERROR,
					payload: err
				});
			});

		return feedbackPromise;
	};
}

export function submitFeedbackForm(data) {
	return (dispatch) => {
		dispatch(submitFeedbackFormWithoutMessage(data))
			.then(() => {
				dispatch(
					systemMessageActions.createMessage({
						text: 'Спасибо, ваше сообщение отправлено'
					})
				);
			})
			.catch(() => {
				dispatch(
					systemMessageActions.createMessage({
						text: 'При отправке произошла ошибка. Попробуйте еще\u00A0раз.'
					})
				);
			});
	};
}
