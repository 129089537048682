import * as types from './types';

const initialState = {
	items: [],
	total: null,
	error: null,
	loading: false
};

const reviewsReducers = (state = initialState, action) => {
	const { payload, type } = action;

	switch (type) {
		case types.GET_REVIEWS:
			return { ...state, loading: true };

		case types.GET_REVIEWS_SUCCESS:
			return {
				...state,
				items: state.items.concat(payload.items),
				total: payload.total,
				loading: false
			};

		case types.GET_REVIEWS_FAILED:
			return { ...state, error: payload, loading: false };

		default:
			return state;
	}
};

export default reviewsReducers;
