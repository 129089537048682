import * as types from './types';

export function createMessage(data) {
	return {
		type: types.CREATE_SYSTEM_MESSAGE,
		payload: data
	};
}

export function removeMessage() {
	return {
		type: types.REMOVE_SYSTEM_MESSAGE
	};
}
