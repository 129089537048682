import * as types from './types';

const initialState = {
	filterValues: {},
	isLoading: true,
	error: false
};

const catalogFilterReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.GET_FILTER_VALUES_START:
			return { ...state, isLoading: true };
		case types.GET_FILTER_VALUES_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: false,
				filterValues: payload
			};
		case types.GET_FILTER_VALUES_ERROR:
			return {
				...state,
				isLoading: false,
				error: payload
			};
		case types.RESET_FILTER_VALUES:
			return initialState;
		default:
			return state;
	}
};

export default catalogFilterReducer;
