import { api } from '@culture/common-helpers/request';

import { systemMessageActions } from 'ducks/systemMessage';
import * as types from './types';

export function submitSubscribeFormWithoutMessage(data) {
	return (dispatch) => {
		dispatch({
			type: types.SUBSCRIBE_FORM_SUBMIT_START
		});

		const subscribersPromise = api('subscribers', data, 'POST');

		subscribersPromise
			.then((response) => {
				dispatch({
					type: types.SUBSCRIBE_FORM_SUBMIT_SUCCESS,
					payload: response
				});
			})
			.catch((err) => {
				dispatch({
					type: types.SUBSCRIBE_FORM_SUBMIT_ERROR,
					payload: err
				});
			});

		return subscribersPromise;
	};
}

export function submitSubscribeForm(data) {
	return (dispatch) => {
		dispatch(submitSubscribeFormWithoutMessage(data))
			.then(() => {
				dispatch(
					systemMessageActions.createMessage({
						text:
							'Вы\u00A0подписались на\u00A0рассылку портала «Культура.РФ». Спасибо. Осталось подтвердить подписку\u00A0— мы\u00A0отправили ссылку на\u00A0вашу электронную почту.'
					})
				);
			})
			.catch(() => {
				dispatch(
					systemMessageActions.createMessage({
						text: 'При отправке произошла ошибка. Попробуйте еще\u00A0раз.'
					})
				);
			});
	};
}
