import { api } from '@culture/common-helpers/request';

import * as types from './types';
import transformers from './transformers';

export function fetchFavorites() {
	return (dispatch) => {
		dispatch({
			type: types.FAVORITES_FETCH_START
		});

		api('favorites/me').then(
			(response) => {
				dispatch({
					type: types.FAVORITES_FETCH_SUCCESS,
					payload: transformers.favorites(response)
				});
			},
			(err) => {
				dispatch({
					type: types.FAVORITES_FETCH_ERROR,
					payload: err
				});
			}
		);
	};
}

export function changeFavorites(action, data) {
	return (dispatch) => {
		dispatch({
			type: types.FAVORITES_PATCH_START
		});

		const promise = api(`favorites/${action}`, data, 'POST');

		promise.then(
			(response) => {
				dispatch({
					type: types.FAVORITES_PATCH_SUCCESS,
					payload: transformers.favorites(response)
				});
			},
			(err) => {
				dispatch({
					type: types.FAVORITES_PATCH_ERROR,
					payload: err
				});
			}
		);

		return promise;
	};
}
