import * as types from './types';

const userLocationReducer = (state = { userLocation: null }, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.GET_USER_LOCATION_START:
			return { ...state, isLoading: true };
		case types.GET_USER_LOCATION_SUCCESS:
			return { ...state, isLoading: false, userLocation: payload };
		case types.GET_USER_LOCATION_ERROR:
			return { ...state, isLoading: false, error: payload.message };
		case types.SET_USER_LOCATION:
			return { ...state, userLocation: payload };
		default:
			return state;
	}
};

export default userLocationReducer;
