import { api as apiRequest } from '@culture/common-helpers/request';

import { systemMessageActions } from '../systemMessage';
import * as types from './types';

export const getEvents = (params) => async (dispatch) => {
	dispatch({
		type: types.GET_EVENTS
	});

	try {
		const events = await apiRequest('actualEvents', params);

		dispatch({
			type: types.GET_EVENTS_SUCCESS,
			payload: events
		});
	} catch (error) {
		dispatch({
			type: types.GET_EVENTS_FAILED,
			payload: error
		});
	}
};

export default {
	getEvents
};

export function submitEntityFeedbackFormWithoutMessage(data) {
	return (dispatch) => {
		dispatch({
			type: types.ENTITY_FEEDBACK_FORM_SUBMIT_START
		});

		const feedbackPromise = apiRequest('entitiesFeedback', data, 'POST');

		feedbackPromise
			.then((response) => {
				dispatch({
					type: types.ENTITY_FEEDBACK_FORM_SUBMIT_SUCCESS,
					payload: response
				});
			})
			.catch((err) => {
				dispatch({
					type: types.ENTITY_FEEDBACK_FORM_SUBMIT_ERROR,
					payload: err
				});
			});

		return feedbackPromise;
	};
}

export function submitEntityFeedbackForm(data) {
	return (dispatch) => {
		dispatch(submitEntityFeedbackFormWithoutMessage(data))
			.then(() => {
				dispatch(
					systemMessageActions.createMessage({
						text: 'Спасибо, ваш отзыв отправлен'
					})
				);
			})
			.catch(() => {
				dispatch(
					systemMessageActions.createMessage({
						text: 'При отправке произошла ошибка. Попробуйте еще раз.'
					})
				);
			});
	};
}

export function setEventVisited(data) {
	return (dispatch) => {
		dispatch({
			type: types.ENTITY_SET_VISITED_START
		});

		const promise = apiRequest('personalizations/visited', data, 'POST');

		promise
			.then((response) => {
				dispatch({
					type: types.ENTITY_SET_VISITED_SUCCESS,
					payload: response
				});
			})
			.catch((err) => {
				dispatch({
					type: types.ENTITY_SET_VISITED_ERROR,
					payload: err
				});
			});

		return promise;
	};
}
