import reducer from './reducers';

import * as favoriteActions from './actions';
import * as favoriteTypes from './types';
import * as favoriteOperations from './operations';
import * as favoriteSelectors from './selectors';

export {
	favoriteActions,
	favoriteTypes,
	favoriteOperations,
	favoriteSelectors
};

export default reducer;
