import reducer from './reducers';

import * as catalogsActions from './actions';
import * as catalogsOperations from './operations';
import * as catalogsTypes from './types';
import * as catalogsSelectors from './selectors';

export {
	catalogsActions,
	catalogsOperations,
	catalogsTypes,
	catalogsSelectors
};

export default reducer;
