import App from 'next/app';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import getConfig from 'next/config';
import { api as apiRequest } from '@culture/common-helpers/request';
import { redirect } from '@culture/common-helpers/redirect';
import { addCleanCache } from '@culture/common-helpers/params';
import { useStore } from 'store';
import GlobalData from 'containers/GlobalData';
import { ImageFormatProvider } from 'contexts/ImageFormatContext';
import { GlobalElementsProvider } from 'contexts/GlobalElementsContext';
import Fonts from 'components/Fonts';

import 'styles/global.scss';
import { PVIProvider } from 'contexts/PVIContext';
import { prepareMenu } from 'utils/helpers/menu';

function FrontendApp({ Component, pageProps, imageFormat }) {
	const router = useRouter();

	useEffect(() => {
		router.events.on('routeChangeStart', () => NProgress.start());
		router.events.on('routeChangeComplete', () => NProgress.done());
		router.events.on('routeChangeError', () => NProgress.done());

		return () => {
			router.events.off('routeChangeError', () => NProgress.done());
			router.events.off('routeChangeStart', () => NProgress.start());
			router.events.off('routeChangeComplete', () => NProgress.done());
		};
	}, [router]);

	useEffect(() => {
		window.addEventListener('load', () => {
			const comment = document.createComment('VK49865');
			const body = document.body || document.getElementsByTagName('body')[0];
			body.appendChild(comment);
		});
	}, []);

	const store = useStore(pageProps.initialReduxState);

	return (
		<Provider store={store}>
			<CookiesProvider>
				<GlobalData>
					<GlobalElementsProvider>
						<ImageFormatProvider imageFormat={imageFormat}>
							<PVIProvider>
								<Component {...pageProps} />
							</PVIProvider>
						</ImageFormatProvider>
						<Fonts />
					</GlobalElementsProvider>
				</GlobalData>
			</CookiesProvider>
		</Provider>
	);
}

if (!process.browser) {
	const { serverRuntimeConfig } = getConfig();

	// eslint-disable-next-line
  const serverLogger = require('../server/utils/logger');

	const getGlobalPromoBlocks = async (url) => {
		let promoBlocks;

		try {
			promoBlocks = await apiRequest('promoBlocks/global', {
				url
			});
		} catch (err) {
			serverLogger.logError(err.message);

			promoBlocks = [];
		}

		return promoBlocks;
	};

	const getTopPromoBlock = async (url, cleanCache) => {
		let promoBlocks;

		try {
			promoBlocks = await apiRequest(
				'promoBlocks/top',
				addCleanCache(
					{
						url,
						cached: true
					},
					cleanCache
				)
			);
		} catch (err) {
			serverLogger.logError(err.message);

			promoBlocks = null;
		}

		return promoBlocks;
	};

	const acceptTypeRegExp = /(?<=^|,).+?(?=$|,|;)/g;

	// Only uncomment this method if you have blocking data requirements for
	// every single page in your application. This disables the ability to
	// perform automatic static optimization, causing every page in your app to
	// be server-side rendered.
	//

	FrontendApp.getInitialProps = async (appContext) => {
		const { req, res, asPath, query } = appContext.ctx;

		const cleanCache =
			typeof query?.cleanCache === 'boolean' ? query.cleanCache : false;

		res.setHeader('Cache-Control', serverRuntimeConfig.cacheControl.default);

		serverLogger.logInfo(
			`${new Date()} Request URL: ${req.url}; Method: ${req.method};`
		);

		const redirectData = await redirect(asPath, serverLogger);

		if (redirectData) {
			res.redirect(redirectData.status, redirectData.url);

			return {};
		}

		// calls page's `getInitialProps` and fills `appProps.pageProps`
		const appProps = await App.getInitialProps(appContext);

		const menu = await apiRequest('menu/tree');
		const globalPromoBlocks = await getGlobalPromoBlocks(asPath);
		const topPromoBlock = await getTopPromoBlock(asPath, cleanCache);

		const acceptHeader = req.headers.accept || '';
		const acceptedTypes = new Set(acceptHeader.match(acceptTypeRegExp));
		const imageFormat = serverRuntimeConfig.imageFormats.find((format) =>
			acceptedTypes.has(`image/${format}`)
		);
		return {
			...appProps,
			imageFormat,
			pageProps: {
				...appProps.pageProps,
				menu: prepareMenu(menu.children),
				globalPromoBlocks,
				topPromoBlock: {
					title: topPromoBlock?.title,
					url: topPromoBlock?.url,
					images: topPromoBlock?.images,
					type: topPromoBlock?.type,
					text: topPromoBlock?.text
				}
			}
		};
	};
}

FrontendApp.propTypes = {
	Component: PropTypes.elementType.isRequired,
	pageProps: PropTypes.object,
	imageFormat: PropTypes.string
};

FrontendApp.defaultProps = {
	pageProps: {}
};

export default FrontendApp;
