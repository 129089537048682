import { api as apiRequest } from '@culture/common-helpers/request';

import * as types from './types';

export const getReviews = (params) => async (dispatch) => {
	dispatch({
		type: types.GET_REVIEWS
	});

	try {
		const reviews = await apiRequest('reviews', params);

		dispatch({
			type: types.GET_REVIEWS_SUCCESS,
			payload: reviews
		});
	} catch (error) {
		dispatch({
			type: types.GET_REVIEWS_FAILED,
			payload: error
		});
	}
};

export default {
	getReviews
};
