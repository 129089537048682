import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const ImageFormatContext = createContext(null);

const ImageFormatProvider = ({ imageFormat, children }) => (
	<ImageFormatContext.Provider value={imageFormat}>
		{children}
	</ImageFormatContext.Provider>
);

ImageFormatProvider.propTypes = {
	imageFormat: PropTypes.string,
	children: PropTypes.node.isRequired
};

ImageFormatProvider.defaultProps = {
	imageFormat: null
};

export { ImageFormatContext, ImageFormatProvider };
