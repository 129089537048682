/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import pvi from 'pvi';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

const PVIContext = createContext(null);

const PVIProvider = (props) => {
	const [pviInstanse, setPviInstanse] = useState(null);
	const [panelHeight, setPanelHeight] = useState(0);
	const router = useRouter();
	const [
		{
			pvi_panelActive,
			pvi_builtElements,
			pvi_fontFamily,
			pvi_fontSize,
			pvi_images,
			pvi_lang,
			pvi_letterSpacing,
			pvi_lineHeight,
			pvi_panelFixed,
			pvi_panelHide,
			pvi_reload,
			pvi_speech,
			pvi_target,
			pvi_theme
		}
	] = useCookies(['pvi_panelActive', 'pvi_panelHide', 'pvi_images']);

	useEffect(() => {
		if (!pviInstanse) {
			setPviInstanse(
				new pvi.Pvi({
					speech: false,
					builtElements: true,
					disabled: {
						themes: ['brown', 'green'],
						images: ['off'],
						extraParams: true
					}
				})
			);
		}

		const ResizeObserverImplementation =
			'ResizeObserver' in window ? ResizeObserver : ResizeObserverPolyfill;

		const pviPanelResizeObserver = new ResizeObserverImplementation(
			(entries) => {
				// eslint-disable-next-line no-restricted-syntax
				for (const entry of entries) {
					const { borderBoxSize } = entry;
					const height = borderBoxSize[0].blockSize;
					setPanelHeight(height);

					document.documentElement.style.setProperty(
						'--pvi-panel-height',
						height === 0 ? 'unset' : `${height}px`
					);
				}
			}
		);
		const panel = document.querySelector('.pvi-panel');
		if (panel) {
			pviPanelResizeObserver.observe(panel);
		}
		return () => {
			pviPanelResizeObserver.disconnect();
		};
	}, [router.asPath, pvi_panelActive, pvi_panelHide]);

	useEffect(() => {
		document.documentElement.style.setProperty(
			'--pvi-filter',
			pvi_images === 'grayscale' && 'grayscale(1)'
		);
	}, [pvi_images]);

	return (
		<PVIContext.Provider
			value={{
				panelActive: Boolean(pvi_panelActive),
				builtElements: pvi_builtElements,
				fontFamily: pvi_fontFamily,
				fontSize: pvi_fontSize,
				images: pvi_images,
				lang: pvi_lang,
				letterSpacing: pvi_letterSpacing,
				lineHeight: pvi_lineHeight,
				panelFixed: pvi_panelFixed,
				panelHide: pvi_panelHide,
				reload: pvi_reload,
				speech: pvi_speech,
				target: pvi_target,
				theme: pvi_theme,
				panelHeight,
				pviInstanse
			}}
		>
			{props.children}
		</PVIContext.Provider>
	);
};

PVIProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { PVIContext, PVIProvider };
