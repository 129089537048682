import { createMessage } from './actions';

export const withSystemMessage = (promise, successMessage, errorMessage) => (
	dispatch
) => {
	promise.then(
		(response) => {
			if (successMessage) {
				return dispatch(createMessage({ text: successMessage }));
			}

			return response;
		},
		(err) => {
			if (errorMessage || err.userMessage) {
				dispatch(
					createMessage({
						text: errorMessage || err.userMessage
					})
				);
			}
		}
	);

	return promise;
};
