export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';
export const RESET_EVENTS = 'RESET_EVENTS';

export const ENTITY_FEEDBACK_FORM_SUBMIT_START =
	'ENTITY_FEEDBACK_FORM_SUBMIT_START';
export const ENTITY_FEEDBACK_FORM_SUBMIT_SUCCESS =
	'ENTITY_FEEDBACK_FORM_SUBMIT_SUCCESS';
export const ENTITY_FEEDBACK_FORM_SUBMIT_ERROR =
	'ENTITY_FEEDBACK_FORM_SUBMIT_ERROR';

export const ENTITY_SET_VISITED_START = 'ENTITY_SET_VISITED_START';
export const ENTITY_SET_VISITED_SUCCESS = 'ENTITY_SET_VISITED_SUCCESS';
export const ENTITY_SET_VISITED_ERROR = 'ENTITY_SET_VISITED_ERROR';
