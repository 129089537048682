import { api } from '@culture/common-helpers/request';

import * as types from './types';

export function fetchCurrentUser() {
	return (dispatch) => {
		dispatch({
			type: types.CURRENT_USER_FETCH_START
		});

		const promise = api('users/me');

		promise.then(
			(response) => {
				dispatch({
					type: types.CURRENT_USER_FETCH_SUCCESS,
					payload: response
				});
			},
			(err) => {
				dispatch({
					type: types.CURRENT_USER_FETCH_ERROR,
					payload: err
				});
			}
		);

		return promise;
	};
}
