import _ from 'underscore';

import * as actions from './actions';

export const getFilterValues = (url, filterValues, cached) => (dispatch) => {
	dispatch(
		actions.getPossibleFilterValues(
			url,
			_(filterValues).omit('sort', 'page', 'query', 'limit'),
			cached
		)
	);
};
