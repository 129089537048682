import {
	favoritesEntityTitles,
	singleFavoriteTitles
} from '@culture/utils/helpers/favorites';

export const successAddFavoritesMessage = (title, entityType) =>
	`${
		singleFavoriteTitles[entityType]
	} «${title}» добавлено в «Избранное». Все сохраненные ${favoritesEntityTitles[
		entityType
	]?.toLowerCase()} вы можете посмотреть в личном кабинете.`;
