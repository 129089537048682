import * as types from './types';

const initialState = {
	items: [],
	total: null,
	error: null,
	loading: false
};

const eventsReducers = (state = initialState, action) => {
	const { payload, type } = action;

	switch (type) {
		case types.GET_EVENTS:
			return { ...state, loading: true };

		case types.GET_EVENTS_SUCCESS:
			return {
				...state,
				items: state.items.concat(payload.items),
				total: payload.total,
				loading: false
			};

		case types.GET_EVENTS_FAILED:
			return { ...state, error: payload, loading: false };

		case types.RESET_EVENTS:
			return initialState;

		default:
			return state;
	}
};

export default eventsReducers;
