import * as types from './types';

const systemMessageReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.CREATE_SYSTEM_MESSAGE:
			return { ...state, message: payload };
		case types.REMOVE_SYSTEM_MESSAGE:
			return { ...state, message: null };
		default:
			return state;
	}
};

export default systemMessageReducer;
