import _ from 'underscore';
import React, { createContext, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const GlobalElementsContext = createContext([(...names) => [], _.noop]);

const GlobalElementsProvider = (props) => {
	const elements = useRef({});

	const setElementRef = useCallback((name, element) => {
		elements.current = { ...elements.current, [name]: element };
	}, []);

	const getElements = useCallback(
		(...names) => names.map((name) => elements.current[name]?.current),
		[]
	);

	return (
		<GlobalElementsContext.Provider value={[getElements, setElementRef]}>
			{props.children}
		</GlobalElementsContext.Provider>
	);
};

GlobalElementsProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export { GlobalElementsContext, GlobalElementsProvider };
