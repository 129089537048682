import * as types from './types';

const subscribeFormReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.SUBSCRIBE_FORM_SUBMIT_START:
			return { ...state, isLoading: true };
		case types.SUBSCRIBE_FORM_SUBMIT_SUCCESS:
			return { ...state, isLoading: false, subscribeResult: payload };
		case types.SUBSCRIBE_FORM_SUBMIT_ERROR:
			return { ...state, isLoading: false, error: payload.message };
		default:
			return state;
	}
};

export default subscribeFormReducer;
