import { systemMessageOperations } from 'ducks/systemMessage';
import * as actions from './actions';

export const addFavorites = (data, params = {}) => {
	const { successMessage } = params;

	return (dispatch) =>
		dispatch(
			systemMessageOperations.withSystemMessage(
				dispatch(actions.changeFavorites('add', data)),
				successMessage
			)
		);
};

export const removeFavorites = (data) => (dispatch) =>
	dispatch(
		systemMessageOperations.withSystemMessage(
			dispatch(actions.changeFavorites('remove', data))
		)
	);
