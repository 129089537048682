import * as types from './types';

const favoritesReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.FAVORITES_FETCH_START:
		case types.FAVORITES_PATCH_START:
			return { ...state, isLoading: true };
		case types.FAVORITES_FETCH_SUCCESS:
		case types.FAVORITES_PATCH_SUCCESS:
			return { ...state, isLoading: false, favorites: payload };
		case types.FAVORITES_FETCH_ERROR:
		case types.FAVORITES_PATCH_ERROR:
			return { ...state, isLoading: false, error: payload.message };
		default:
			return state;
	}
};

export default favoritesReducer;
