import * as types from './types';

const currentUserReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case types.FAQ_PUBLICATION_FETCH_START:
			return { ...state, isLoading: true };
		case types.FAQ_PUBLICATION_FETCH_SUCCESS:
			return { ...state, isLoading: false, faqPublication: payload };
		case types.FAQ_PUBLICATION_FETCH_ERROR:
			return { ...state, isLoading: false, error: payload.message };
		default:
			return state;
	}
};

export default currentUserReducer;
