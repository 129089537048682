import _ from 'underscore';
import { startOfDay, endOfDay, parseISO } from 'date-fns';

import { preparePathParams } from '@culture/common-helpers/params';
import { filterQuery } from '@culture/common-helpers/url';

import { getEvents } from './actions';

export const getSpecialAfishaEvents = ({
	seanceStartDate,
	seanceEndDate,
	...params
}) => async (dispatch) => {
	const eventsParams = filterQuery({
		..._(params).pick('cleanCache', 'limit', 'page', 'query'),
		seanceStartDate: seanceStartDate && startOfDay(parseISO(seanceStartDate)),
		seanceEndDate: seanceEndDate && endOfDay(parseISO(seanceEndDate)),
		cached: true,
		fields: [
			'title',
			'thumbnailFile',
			'name',
			'seancesCount',
			'seances.placeIndex',
			'seances.startDate',
			'seances.endDate',
			'seanceStartDate',
			'genres',
			'places.locale',
			'places.title',
			'genres',
			'price',
			'tags',
			'isPushkinsCard',
			'isPremiere'
		].join(),
		isSortByNearestSeance: true,
		isOnlyActual: true,
		locationName: params.locale,
		isTechnicalEvent: true
	});

	const preparedEventsParams = preparePathParams(
		eventsParams,
		_(params).pick('genre'),
		'specialAfisha'
	);

	await dispatch(getEvents(preparedEventsParams));
};

export default {
	getSpecialAfishaEvents
};
