import _ from 'underscore';

import * as actions from './actions';

export const getReviews = ({ ...params }) => async (dispatch) => {
	const reviewsParams = _({
		fields: ['_id', 'title', 'thumbnailFile', 'text'].join(),
		statuses: ['published'],
		sort: '-publishDate',
		cached: true
	}).extend(params);

	await dispatch(actions.getReviews(reviewsParams));
};

export default {
	getReviews
};
