import { api } from '@culture/common-helpers/request';
import getConfig from 'next/config';

import * as types from './types';

const { publicRuntimeConfig } = getConfig();

export function fetchFaqPublication() {
	return (dispatch) => {
		dispatch({
			type: types.FAQ_PUBLICATION_FETCH_START
		});

		api(`publications/${publicRuntimeConfig.entities.faqPublicationId}`).then(
			(response) => {
				dispatch({
					type: types.FAQ_PUBLICATION_FETCH_SUCCESS,
					payload: response
				});
			},
			(err) => {
				dispatch({
					type: types.FAQ_PUBLICATION_FETCH_ERROR,
					payload: err
				});
			}
		);
	};
}
