import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';

import { currentUserOperations } from 'ducks/currentUser';

const GlobalData = ({ children, cookies }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(currentUserOperations.fetchCurrentUser(cookies));
	}, []);

	return children;
};

GlobalData.propTypes = {
	children: PropTypes.node.isRequired,
	cookies: PropTypes.instanceOf(Cookies).isRequired
};

export default withCookies(GlobalData);
