import _ from 'underscore';
import { favoriteActions, favoriteOperations } from 'ducks/favorites';

import { successAddFavoritesMessage } from 'utils/helpers/messages';

import * as userActions from './actions';

export const afterAuthentication = (data, cookies) => (dispatch) => {
	const favorite = cookies.get('favorite');

	if (favorite) {
		dispatch(
			favoriteOperations.addFavorites(_(favorite).omit('title'), {
				successMessage: successAddFavoritesMessage(
					favorite.title,
					favorite.entityType
				)
			})
		).catch(() => {
			dispatch(favoriteActions.fetchFavorites());
		});

		cookies.remove('favorite', { path: '/' });
	} else {
		dispatch(favoriteActions.fetchFavorites());
	}
};

export const fetchCurrentUser = (cookies) => (dispatch) =>
	dispatch(userActions.fetchCurrentUser()).then(
		(response) => dispatch(afterAuthentication(response, cookies)),
		() => {}
	);
