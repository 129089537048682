import { createSelector } from 'reselect';

export const selectFilterValues = createSelector(
	(state) => state.catalogs,
	(state) => state.filterValues
);

export const selectFiltersLoading = createSelector(
	(state) => state.catalogs,
	(state) => state.isLoading
);
