import { useMemo } from 'react';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import * as reducers from './ducks';

let store;

function initStore(initialState) {
	return createStore(
		combineReducers(reducers),
		initialState,
		composeWithDevTools(applyMiddleware(thunkMiddleware))
	);
}

export const initializeStore = (preloadedState) => {
	// eslint-disable-next-line no-underscore-dangle
	let _store = store || initStore(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = initStore({
			...store.getState(),
			...preloadedState
		});
		// Reset the current store
		store = undefined;
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') {
		return _store;
	}
	// Create the store once in the client
	if (!store) {
		store = _store;
	}

	return _store;
};

export function useStore(initialState) {
	return useMemo(() => initializeStore(initialState), [initialState]);
}
