import { api } from '@culture/common-helpers/request';

import * as types from './types';

export function getUserLocation() {
	return (dispatch) => {
		dispatch({
			type: types.GET_USER_LOCATION_START
		});

		api('userLocation')
			.then((response) => {
				dispatch({
					type: types.GET_USER_LOCATION_SUCCESS,
					payload: response
				});
			})
			.catch((err) => {
				dispatch({
					type: types.GET_USER_LOCATION_ERROR,
					payload: err
				});
			});
	};
}

export function setUserLocation(location) {
	return {
		type: types.SET_USER_LOCATION,
		payload: location
	};
}
